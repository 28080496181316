import React from "react";

import { useDeclaration } from "../hooks/strapi";

import Layout from "../layout";
import SEO from "../components/seo";

import { Container, Row, Col } from "reactstrap";
import Heading from "../components/atoms/Heading/Heading";

const PrivacyPolicy = () => {
    const declaration = useDeclaration();

    return (
        <Layout>
            <SEO title="Deklaracja dostępności" />
            <Container className="mb-5 pb-5">
                <Row className="mb-5 pb-5">
                    <Col lg={{ size: 10, offset: 1 }}>
                        <Heading className="mt-5">{declaration.name}</Heading>
                        <div
                            className="declaration-modal"
                            dangerouslySetInnerHTML={{
                                __html: declaration.description,
                            }}
                        ></div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default PrivacyPolicy;
